// @import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&family=Noto+Sans+TC:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Homemade+Apple&family=Noto+Sans&display=swap');
@import "~bootstrap/scss/functions";
@import 'bootstrap-vars.scss';
@import "~bootstrap/scss/bootstrap";
@import "~video-react/styles/scss/video-react";
@import 'react-chat-elements/dist/main.css';



.bg-darkened {
    background: linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .65)); }

.bg-highlighted {
    background: linear-gradient(rgba( #004e8c, .85), rgba(darken($secondary, 35%), .75)); }

h1 {
    text-shadow: .007em .007em .35em rgb(223, 61, 46);
    font-weight: 700 !important; }

.App-logo {
    text-shadow: 3px 3px 6px rgb(255, 255, 255); }

.text-black {
    color: #000; }

a:hover {
    text-decoration: none;
    color: #d91a72; }

.priceCategoryButton {
    transition: all .4s;
    background-size: cover;
    background-position: center center;
    font-size: 1.4em;
    text-shadow: .1em .1em .4em rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 8em; }

.priceCategoryButton:hover {
    // border: 2px solid #ffffff !important
    box-shadow: 0 0 15px rgba(255, 255, 255, .8) !important;
    background-position: top center; }

.rce-mlist {
    height: 350px !important; }

.rce-input {
    width: 100%; }

.rce-input-buttons {
    margin-left: 7px !important; }

.comment-time {
    color: rgba(0, 0, 0, 0.45); }
